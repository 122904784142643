<template>
  <div :class="cClass">
    <AButton
      :disabled="!hasPrev()"
      class="a-pagination__button-prev a-pagination__button"
      variant="link"
      @click="changePage(cPrevPage)"
    >
      <AIcon name="arrow-right-sc" />
    </AButton>
    <div class="pages-array d-flex align-items-center">
      <AButton
        v-if="cRangePageStart !== 1"
        variant="link"
        class="first-page a-pagination__button"
        @click="changePage(1)"
      >
        1
      </AButton>
      <div class="sep" v-if="cRangePageStart !== 1">-</div>
      <div class="array">
        <AButton
          :key="i"
          v-for="i in pagesArray"
          class="a-pagination__button"
          :class="[currentPage === i ? 'active' : '']"
          variant="link"
          @click="changePage(i)"
          >{{ i }}
        </AButton>
      </div>
      <div class="sep" v-if="currentPage <= cTotalPage - 3">-</div>
      <AButton
        variant="link"
        class="total-pages a-pagination__button"
        v-if="currentPage <= cTotalPage - 3"
        @click="changePage(cTotalPage)"
      >
        {{ cTotalPage }}
      </AButton>
    </div>
    <AButton
      :disabled="!hasNext()"
      class="a-pagination__button-next a-pagination__button"
      variant="link"
      @click="changePage(cNextPage)"
    >
      <AIcon name="arrow-right-sc" />
    </AButton>
  </div>
</template>

<script>
export default {
  name: "pagination",
  props: {
    currentPage: { type: Number, default: 1 },
    total: { type: Number, default: 0 },
    perPage: { type: Number, default: 10 },
    rangePage: { type: Number, default: 1 },
    position: {
      type: String,
      default: "center",
      validator: str => ["center", "start", "end"].includes(str)
    }
  },
  computed: {
    pagesArray() {
      let array = [];
      for (let i = this.cRangePageStart; i <= this.cRangePageEnd; i++) {
        array.push(i);
      }
      return array;
    },
    cRangePageStart() {
      let start = this.currentPage - this.rangePage;

      return start > 0 && this.currentPage > 3
        ? this.currentPage < this.cTotalPage - 2
          ? start
          : this.cTotalPage - 2
        : 1;
    },
    cRangePageEnd() {
      let end = this.currentPage + this.rangePage;
      return this.cTotalPage > 2
        ? this.currentPage > 3
          ? end < this.cTotalPage - 1
            ? end
            : this.cTotalPage
          : 3
        : 2;
    },
    cTotalPage() {
      return Math.ceil(this.total / this.perPage);
    },
    cNextPage() {
      return this.currentPage + 1;
    },
    cPrevPage() {
      return this.currentPage - 1;
    },
    cClass() {
      return [
        "a-pagination",
        "d-flex",
        [`align-items-center`],
        {
          [`justify-content-${this.position}`]: this.position
        }
      ];
    }
  },
  methods: {
    hasPrev() {
      return +this.currentPage > 1;
    },
    hasNext() {
      return +this.currentPage < +this.cTotalPage;
    },
    changePage(page) {
      this.$emit("update:currentPage", page);
      this.$emit("page-changed", page);
    }
  }
};
</script>

<style scoped lang="scss">
.a-pagination {
  &__button,
  .sep {
    font-size: 1rem;
    font-weight: 600;
    line-height: 24px;
    color: var(--th-gray);
    padding: 10px 19px;
    transition: 300ms color;

    svg {
      &::v-deep {
        * {
          transition: 300ms color;
          stroke: var(--th-gray);
        }
      }
    }

    &-prev,
    &-next {
    }

    &-prev {
      padding-left: 0;

      svg {
        transform: rotate(180deg);
      }
    }

    &-next {
      padding-right: 0;
    }

    &.active,
    &:hover {
      color: var(--th-orange);

      svg {
        &::v-deep {
          * {
            stroke: var(--th-orange);
          }
        }
      }
    }
  }
}
</style>
